<template>
  <div>
    <b-card>
      <b-row>
        <h6 class="section-label mx-1">Attributess</h6>
      </b-row>
    </b-card>
    <div>
      <b-form ref="form" :style="{ height: trHeight }" class="repeater-form">
        <!-- Row Loop -->
        <div ref="row" class="pb-2">
          <b-card>
            <b-row>
              <b-col md="8">
                <b-row>
                  <!-- Attributes Name -->
                  <b-col md="4">
                    <b-form-group label="Attributes Name" label-for="attributes-name">
                      <b-form-input id="attributes-name" type="text" v-model="item.title" placeholder="Attributes Name" />
                    </b-form-group>
                  </b-col>
                  <!-- Attributes Code -->
                  <b-col md="4">
                    <b-form-group label="Attributes Required" label-for="attributes-required">
                      <b-form-select id="attribute-required" v-model="item.required" :options="optionsRequired" />
                    </b-form-group>
                  </b-col>

                  <!-- Attributes Type -->
                  <b-col md="4">
                    <b-form-group label="Attributes Type" label-for="attributes-type">
                      <b-form-select id="attribute-type" v-model="item.type" :options="optionsType" />
                    </b-form-group>
                  </b-col>

                  <b-col md="4" v-if="item.type == 'multi'">
                    <b-form-group label="Attributes Minimum Amount To Select" label-for="attributes-min_select">
                      <b-form-input id="attributes-min_select" type="text" v-model="item.min_select" placeholder="Attributes Min selection" />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" v-if="item.type == 'multi'">
                    <b-form-group label="Attributes Maxiumum Amount To Select" label-for="attributes-max_select">
                      <b-form-input id="attributes-max_select" type="text" v-model="item.max_select" placeholder="Attributes Max selection" />
                    </b-form-group>
                  </b-col>

                  <!-- Content -->
                  <b-col md="12">
                    <b-form-group :label="'Description'" label-for="category-description">
                      <b-form-textarea id="category-description" type="text" v-model="item.content" placeholder="Attributes Description" />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group :label="'Pirority'" label-for="category-description">
                      <b-form-input id="attributes-priority" type="text" v-model="item.priority" placeholder="Priority" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Options -->
                <template v-if="item.type == 'select' || item.type == 'multi'" :ref="'rowSub'" class="row">
                  <h5>Options List</h5>
                  <b-row v-for="(sub, subIndex) in item.option_values" :key="subIndex">
                    <b-col md="6">
                      <b-form-group label="Option Name" :label-for="'option-name' + subIndex">
                        <b-form-input :id="'option-name' + subIndex" type="text" v-model="sub.value" placeholder="Option Name" />
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Option Price Change" :label-for="'attributes-price' + subIndex">
                        <b-form-input :id="'attributes-price' + subIndex" type="number" v-model="sub.price" placeholder="Option Price Change" />
                      </b-form-group>
                    </b-col>

                    <b-col md="2" v-if="subIndex != 0">
                      <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="danger" class="mt-0 mt-md-2" block @click="removeSubItem(subIndex)">
                        <feather-icon icon="XIcon" class="mr-25" />
                        <span>Remove</span>
                      </b-button>
                    </b-col>
                    <b-col md="2" v-if="subIndex == 0">
                      <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="info" class="mt-0 mt-md-2" block @click="addSubItem()">
                        <feather-icon icon="PlusIcon" class="mr-25" />
                        <span>Add Option</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </template>
              </b-col>

              <b-col md="4">
                <b-form-group :label="'Assigned Products'" label-for="vat-rate-products_tagged" class="producttag">
                  <v-select id="vat-rate-product" v-model="item.products" multiple :options="productsOptions" disabled aria-disabled />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- create Button -->
              <b-col lg="2" md="2" class="mb-50">
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="success" class="mt-0 mt-md-2" block @click="createOption()">
                  <feather-icon icon="SaveIcon" class="mr-25" />
                  <span>Create </span>
                </b-button>
              </b-col>
              <b-col lg="2" md="2" class="mb-50">
                <router-link tag="div" :to="'/takeaway/products/attributes/'" class="ml-25 mt-2 btn btn-warning pull-right">
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Cancel</span>
                </router-link>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BFormSelect, BCard, BFormTextarea } from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

import vSelect from "vue-select";

import _ from "lodash";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BForm,
    BRow,
    BFormSelect,
    BCol,
    vSelect,
    BCard,
    BButton,
    BFormGroup,
    BFormTextarea,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      item: {
        option_values: [
          {
            value: "",
            price: 0,
          },
        ],
      },

      products_raw: [],
      productsOptions: [],

      optionsRequired: [
        {
          value: 0,
          text: "No",
        },
        {
          value: 1,
          text: "Yes",
        },
      ],
      optionsType: [
        {
          value: "multi",
          text: "Multiselect Options",
        },
        {
          value: "select",
          text: "Options",
        },
        {
          value: "text",
          text: "Free text Input",
        },
      ],
    };
  },

  watch: {
    item: {
      deep: true,
      handler(val) {
        this.item = val;
        this.$forceUpdate();
      },
    },
  },
  methods: {
    async createOption() {
      try {
        const response = await useJwt.post_attribute("create", {
          title: this.item.title,
          content: this.item.content ? this.item.content : " ",
          type: this.item.type ? this.item.type : "text",
          option_values: JSON.stringify(this.item.option_values),
          required: this.item.required == 1 ? true : false,
          max_select: this.item.max_select ? this.item.max_select : 1,
          min_select: this.item.min_select ? this.item.min_select : 0,
          priority: this.item.priority ? this.item.priority : 0,
        });

        if (this.item.products.length) {
          for (let x = 0; x < this.item.products.length; x++) {
            await useJwt.post_product_attributes("create", {
              product_id: this.item.products[x].value,
              attribute_id: response.data.data.id,
            });
          }
        }

        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Attribute created`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `You have successfully created attribute.`,
          },
        });

        this.$router.push("/takeaway/products/attributes");
      } catch (e) {
        console.error(e);
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't create new attribute on the server.`,
          },
        });
      }
    },
    removeSubItem(subItem) {
      this.item.option_values.splice(subItem, 1);
    },

    addSubItem() {
      this.item.option_values.push({
        value: "",
        price: 0,
      });
    },
  },
  mounted() {
    const userProducts = localStorage.getItem("userProducts");

    if (userProducts) {
      try {
        this.products_raw = JSON.parse(userProducts);
        if (Array.isArray(this.products_raw)) {
          for (let y = 0; y < this.products_raw.length; y++) {
            if (this.products_raw[y]) {
              this.productsOptions.push({
                value: this.products_raw[y].id,
                label: "(#" + this.products_raw[y].id + ") " + this.products_raw[y].title,
              });
            }
          }
        } else {
          console.error("Parsed userProducts is not an array:", this.products_raw);
          this.$router.push("/takeaway/products/attributes");
        }
      } catch (e) {
        console.error("Error parsing userProducts from localStorage:", e);
        this.$router.push("/takeaway/products/attributes");
      }
    } else {
      this.$router.push("/takeaway/products/attributes");
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
